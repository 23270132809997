@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Rubik', sans-serif;
  overflow-x:hidden;
}

* {
  scroll-behavior:smooth;
}

body {
  @apply bg-troo-primaryDark
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif;
}

h1 {
  @apply text-3xl md:text-4xl lg:text-5xl font-bold
}

h2 {
  @apply text-2xl md:text-3xl lg:text-4xl font-bold
}

h3 {
  @apply text-xl sm:text-2xl md:text-3xl font-bold
}

h4 {
  @apply text-lg sm:text-xl md:text-2xl font-bold
}

h5 {
  @apply text-base sm:text-lg md:text-xl font-bold
}

h6 {
  @apply text-sm sm:text-base md:text-lg font-bold
}

.p-1 {
  @apply text-xs sm:text-sm md:text-base
}

.p-2 {
  @apply text-xs sm:text-sm
}

a {
  @apply whitespace-nowrap text-sm md:text-base
}

.main-container {
  @apply max-w-[1920px] mx-auto px-[20px] md:px-[60px] xl:px-[80px] 2xl:px-[250px];
}

.main-container-big {
  @apply max-w-[1920px] mx-auto px-[20px] md:px-[50px] xl:px-[100px];
}

.main-container-big-l-0 {
  @apply max-w-[1920px] mx-auto pr-[20px] md:pr-[50px] xl:pr-[100px] 2xl:pr-[280px];
}

.main-container-big-r-0 {
  @apply max-w-[1920px] mx-auto pl-[20px] md:pl-[50px] xl:pl-[100px] 2xl:pl-[280px];
}

.accordian-input:active .accordian-main {
  background-color:#EBB977 !important;
}

.troo-input {
  @apply p-3 text-sm md:text-base lg:p-3 box-border text-troo-primaryDark outline-none border-b border-troo-primaryDark rounded-md 
}

.height-0,
:where(.collapse > input[type="radio"]) {
  min-height:0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display:none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style:none;
  /* IE and Edge */
  scrollbar-width:none;
  /* Firefox */
}

.btn-filled {
  @apply bg-troo-secondary rounded-full text-troo-primaryLight text-sm md:text-lg md:px-6 md:py-3 px-5 py-3
}
.btn-filled-dark {
  @apply bg-troo-primaryDark rounded-full text-troo-light text-sm md:text-lg md:px-6 md:py-3 px-5 py-3
}
.btn-rect-filled {
  @apply bg-troo-primaryDark text-troo-light text-sm md:text-base md:px-6 md:py-3 px-5 py-3
}

.activeNav{
  @apply text-troo-secondary
}

.btn-outlined {
  @apply bg-transparent text-troo-light rounded-full text-sm md:text-lg md:px-6 md:py-3 px-5 py-3 border border-troo-secondary hover:bg-troo-secondary hover:text-troo-primaryLight
}
.btn-outlined-dark {
  @apply bg-transparent text-troo-primaryLight rounded-full text-sm md:text-lg md:px-6 md:py-3 px-5 py-3 border border-troo-primaryLight hover:bg-troo-primaryLight hover:text-troo-light
}
.btn-rect-outlined {
  @apply bg-transparent text-troo-primaryLight text-sm md:text-base md:px-6 md:py-3 px-5 py-3 border border-troo-primaryLight hover:bg-troo-primaryLight hover:text-troo-light
}